import { fetchDiscoveryURLs } from '@nubank/www-latam-commons/domains/discovery/fetchDiscoveryURLs';
import getInviterRegistrationURL from 'domains/inviter/getInviterRegistrationURL';
import { sentryException } from 'services/errorTracking/sentryException';

const DISCOVERY_URL_MAP_KEY = 'discoveryUrlMap';

export function createDiscoveryRepository(
  fetchDiscovery,
  fetchInviterReggistrationURL,
) {
  const fetchDiscoveryURL = async sessionStorage => {
    if (sessionStorage && sessionStorage.getItem(DISCOVERY_URL_MAP_KEY)) {
      return JSON.parse(sessionStorage.getItem(DISCOVERY_URL_MAP_KEY));
    }
    const discoveryURLs = await fetchDiscovery();
    if (sessionStorage) {
      sessionStorage.setItem(DISCOVERY_URL_MAP_KEY, JSON.stringify(discoveryURLs));
    }
    return discoveryURLs;
  };
  return {
    getRegistrationURL: async (prospectType, fromInviter = false) => {
      try {
        const { discoveryFields: { inviter, unsolicited } } = prospectType;
        let registerURL;
        if (fromInviter) {
          registerURL = await fetchInviterReggistrationURL(inviter);
        }
        if (!registerURL) {
          const discoveryURLs = await fetchDiscoveryURL(sessionStorage);
          registerURL = discoveryURLs && discoveryURLs[unsolicited];
        }
        return registerURL;
      } catch (error) {
        sentryException({
          error,
          flow: 'application_flow',
          checkpoint: 'get_registration_URL',
          namespace: 'createDiscoveryRepository()',
        });
        throw error;
      }
    },
  };
}

export default createDiscoveryRepository(
  fetchDiscoveryURLs,
  getInviterRegistrationURL,
);
