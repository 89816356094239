import { getInviterUrl } from '@nubank/www-latam-commons/utils/environmentUtils';
import { fetchJson } from '@nubank/www-latam-commons/utils/http';

export function fromResponseToInviter({ customer = {}, _links = {} }) {
  const registrationURLs = Object.entries(_links).reduce((acc, curr) => {
    const [inviterField, link] = curr;

    return {
      ...acc,
      [inviterField]: link.href,
    };
  }, {});

  return {
    name: customer.name,
    registrationURLs,
  };
}

export default async function fetchInviter(inviteID, responseTransformer = fromResponseToInviter) {
  const inviterURL = getInviterUrl();
  const inviterURLWithSlug = inviterURL.replace(/:slug/, inviteID);
  const slugResponse = await fetchJson(inviterURLWithSlug);

  if (!slugResponse || !slugResponse.redirect) {
    throw new Error('Slug fetch response unexpected');
  }

  const response = await fetchJson(slugResponse.redirect);
  return responseTransformer(response);
}
