import { css } from 'styled-components';

export const themeSpace = multiplier => ({ theme }) => `${theme.spacing.unit * multiplier}px`;

export const themeFontSize = size => ({ theme }) => theme.fontSize[size];

export const themeFontSizeCss = size => ({ theme }) => css`
  font-size: ${themeFontSize(size)({ theme })}px;
`;

export const inputFieldsBaseSpacing = themeSpace(0.5);

export const paddingBuilder = {
  all: (...args) => {
    const paddingCss = args.reduce(
      (acc, value) => css`${acc} ${themeSpace(value)}`,
      'padding:',
    );

    return css`
      ${paddingCss};
    `;
  },
  top: value => css`
    padding-top: ${themeSpace(value)};
  `,
  right: value => css`
    padding-right: ${themeSpace(value)};
  `,
  bottom: value => css`
    padding-bottom: ${themeSpace(value)};
  `,
  left: value => css`
    padding-left: ${themeSpace(value)};
  `,
  horizontal: (left, right = left) => css`
    padding-left: ${themeSpace(left)};
    padding-right: ${themeSpace(right)};
  `,
  vertical: (top, bottom = top) => css`
    padding-top: ${themeSpace(top)};
    padding-bottom: ${themeSpace(bottom)};
  `,
};
