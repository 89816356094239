import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import { Container } from './styles/Container';
import { Head, HeadImage } from './styles/Head';

const LoaderIcon = dynamic(() => import('./components/LoaderIcon/LoaderIcon'), { ssr: false });

const LABEL_PERCENTAGE = {
  0: 'COMMON.WIDGET.REALTIME_LOADER.0',
  100: 'COMMON.WIDGET.REALTIME_LOADER.100',
};

function RegistrationRealtimeLoader({ percentage, isOpenForm }) {
  return (
    <Container isOpenForm={isOpenForm}>
      <Head>
        <HeadImage>
          <LoaderIcon />
        </HeadImage>
        <Typography
          intlKey={LABEL_PERCENTAGE[percentage] || LABEL_PERCENTAGE[0]}
          color="primary"
          colorVariant="default"
        />
      </Head>
    </Container>
  );
}

RegistrationRealtimeLoader.defaultProps = {
  isOpenForm: false,
  percentage: 0,
};

RegistrationRealtimeLoader.propTypes = {
  isOpenForm: PropTypes.bool,
  percentage: PropTypes.number,
};

export default RegistrationRealtimeLoader;
