import { getMarketingParameters } from '@nubank/www-latam-commons/utils/marketingUtils';
import { getPersistedMGMID } from '@nubank/www-latam-commons/utils/mgmUtils';

export default function getInviterID() {
  const marketingParameters = getMarketingParameters();

  /*
  * We are disabling the timespan feature due to a risk problem as soon as
  * the problem is solved, we should enable this feature again.
  */
  const validID = marketingParameters.id || getPersistedMGMID();

  // TODO: the getPersistedMGMID function returns "null"
  // We can think to return "undefined" instead of "null"
  return validID || undefined;
}
