import getInviter from './core/getInviter';

export function makeGetInviterRegistrationURL(core) {
  return async prospectTypeInviterField => {
    try {
      const { registrationURLs } = await core.getInviter();

      return registrationURLs[prospectTypeInviterField];
    } catch (e) {
      return undefined;
    }
  };
}

const getInviterRegistrationURL = makeGetInviterRegistrationURL({ getInviter });

export default getInviterRegistrationURL;
