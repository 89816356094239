import styled from 'styled-components';

import { themeSpace } from 'styles/themeUtils';

export const Head = styled.div`
  position: relative;
`;

Head.displayName = 'Head';

export const HeadImage = styled.div`
  max-width: 221px;
  margin: 0 auto;
  margin-bottom: ${themeSpace(2)};
`;

HeadImage.displayName = 'HeadImage';
