import fetchInviter from '../repository/fetchInviter';

import getInviterID from './getInviterID';

export function makeGetInviter(core, repository) {
  return async () => {
    const inviteID = core.getInviterID();
    const hasInviteID = Boolean(inviteID);

    // TODO: Improve the error handling
    // https://github.com/nubank/www-latam/pull/689#discussion_r398121772
    if (!hasInviteID) {
      throw new Error('There is no inviter ID');
    }

    return repository.fetchInviter(inviteID);
  };
}

const getInviter = makeGetInviter({ getInviterID }, { fetchInviter });

export default getInviter;
