import styled, { css } from 'styled-components';
import { tablet768 } from '@nubank/nuds-web/styles/breakpoints';

const handleOpenForm = ({ isOpenForm }) => css`
  height: ${isOpenForm ? '100%' : 'calc(100% - 5rem)'};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  overflow: auto;
  width: 100%;
  padding: 2rem;

  ${tablet768`
    padding: 2.5rem;
  `};

  ${handleOpenForm}
`;
