import { accumulateProspectType } from 'utils/prospectTypes';

import returningApplicantsRepository from '../repository/returningApplicants';

export function createReturningApplicantFn(
  {
    getProspectType,
    setProspectType,
    sendToDataLayer,
  },
  currentDate = new Date(),
) {
  return prospectType => {
    const COOKIE_EXPIRATION_IN_YEARS = 2;

    const applicantProspectType = getProspectType();
    const updatedProspectType = applicantProspectType
      ? accumulateProspectType(decodeURIComponent(applicantProspectType), prospectType)
      : prospectType;

    const expirationYear = currentDate.getFullYear() + COOKIE_EXPIRATION_IN_YEARS;
    const expirationDateTimestamp = new Date(currentDate).setFullYear(expirationYear);
    const expirationDate = new Date(expirationDateTimestamp);

    setProspectType(updatedProspectType, expirationDate);
    sendToDataLayer(updatedProspectType);
  };
}

export default createReturningApplicantFn(returningApplicantsRepository);
